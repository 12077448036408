import React from 'react';
import Gallery from '@browniebroke/gatsby-image-gallery'

// Override some of Lightbox options to localise labels in French
const lightboxOptions = {
    imageLoadErrorMessage: 'Slike ni bilo mogoče naložiti',
    nextLabel: 'Naslednja slika',
    prevLabel: 'Prejšna slika',
    zoomInLabel: 'Približaj',
    zoomOutLabel: 'Oddalji',
    closeLabel: 'Zapri',
};

function StaticMainRowGallery({imagesData}) {
    const images = imagesData.map(({ node }) => node.childImageSharp);

    return (
        <div className="rn-portfolio-area mine-gallery-portfolio bg_color--1 pb--120 pt--120 mx-lg-4">

            <div className="container">
                <div className="row align-items-end mb--30">
                    <div className="col-lg-12">
                        <div className="section-title text-left">
                            <h2 className="title">Galerija</h2>
                            <p className="description">Čaroben svet litijskega podzemlja na ogled, <br /> sedaj tudi v glavnem rovu.</p>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="mine-row-gallery-container">
                        <div className="row">
                            <div className="mine-row-gallery-container">
                                <Gallery
                                    thumbAlt="Slika iz galerije glavnega rova rudnika Sitarjevec"
                                    images={images}
                                    lightboxOptions={lightboxOptions}
                                    gutter="0.6rem"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default StaticMainRowGallery;